import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-11-18",
  "title": "Level Up your Coding with Macros",
  "description": "Using editor macros to boost your productivity",
  "cover": "/img/cover/gears.jpg",
  "pic": "/img/gears.jpg",
  "color": "#95b6ef"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Coding can be a most rewarding, enjoyable experience.`}<br parentName="p"></br>{`
`}{`There's nothing like getting into that beautiful `}<a parentName="p" {...{
        "href": "https://www.ted.com/talks/mihaly_csikszentmihalyi_on_flow"
      }}>{`state of flow`}</a>{`.`}<br parentName="p"></br>{`
`}{`But coding also involves some repetitive, tedious parts that can slow you down, take you out of flow, and make it feel like a grind.`}</p>
    <p>{`Usually the best cure for tedium is automation.`}</p>
    <p>{`My favorite video game of all time is StarCraft 2. I was an OK player, hovering in gold or silver league.`}<br parentName="p"></br>{`
`}{`If you're played the game you'll be familiar with the alien Zerg race.`}<br parentName="p"></br>{`
`}{`I loved playing Zerg, but to keep up with your opponent you have to remember to quickly "spawn larvae" with your queens on all your bases every 28 seconds.`}<br parentName="p"></br>{`
`}{`One day I got tired of this annoying, repetitive task and decided to automate it.`}<br parentName="p"></br>{`
`}{`I wrote a little macro that would do the whole task automatically and instantly, every 28 seconds.`}<br parentName="p"></br>{`
`}{`Thanks to my macro I was able to focus my attention on the fun gameplay mechanics. Within a week I had advanced to Diamond league.`}</p>
    <p className="note">
Note: I don't recommend this in games because it's pretty much cheating and I felt bad about it so I stopped using my macro before Blizzard banned me :)
    </p>
    <p>{`But when it comes to coding, this kind of productive cheating is encouraged! `}</p>
    <p>{`Most editors support `}<inlineCode parentName="p">{`snippets`}</inlineCode>{` - a convenient way to write new lines of code.`}<br parentName="p"></br>{`
`}{`Macros on the other hand are fantastic for automating all the repetitive actions you perform on the code you've already written.`}<br parentName="p"></br>{`
`}{`You probably don't even realize how frequently you do the same sequences.`}</p>
    <h2>{`Find Candidates for Automation`}</h2>
    <p>{`The first step to leveling up your coding with macros is to identify the right sequences.`}</p>
    <p>{`Look for sequences that:`}</p>
    <ol>
      <li parentName="ol">{`You use frequently `}</li>
      <li parentName="ol">{`Are composed of many actions`}</li>
    </ol>
    <p>{`For example here's something I noticed I do all the time. I have a line of code (constructor, function call, etc) that I want to adjust.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createPoint({x: 150, Y: 350});
`}</code></pre>
    <p>{`But I still want to keep the original line around until I'm happy with my change. So I copy the line down, move cursor up, comment out the original line, and move the cursor to the new line to edit. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// createPoint({x: 150, Y: 350});
createPoint({x: 150, Y: 350});
`}</code></pre>
    <p>{`Now I can adjust the new line until I'm happy with what it does, and delete the old line.  `}</p>
    <p>{`This little sequence takes 4 actions. So I wrote a macro to do it with a single keystroke. `}</p>
    <p>{`At first it takes some deliberate practice, coding while also paying attention to the meta game: looking for sequences you didn't even realize you depend on daily. `}</p>
    <p>{`Keep a piece of paper next to your keyboard. Every time you use a sequence of actions, name it something that briefly describes it, and write it down on the paper. Also write down the number of actions it takes.`}<br parentName="p"></br>{`
`}{`Every time you use it, put a tally mark next to it. You'll soon have a very clear picture of things you can automate. For example:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sequences.jpg",
        "alt": "sequences"
      }}></img></p>
    <p>{`The more you use a sequence and the more keystrokes a macro would save you the better. `}</p>
    <h2>{`Writing Macros`}</h2>
    <p>{`Before writing a macro, check first to see if your editor already has a command that does what you need. If not, write your own!`}</p>
    <p>{`Many editors like vim, Sublime Text, Webstorm etc have built in support for macros.`}<br parentName="p"></br>{`
`}{`I've been using VS Code lately and am liking it.`}<br parentName="p"></br>{`
`}{`It doesn't have macro support though so I wrote `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=geddski.macros"
      }}>{`an extension`}</a>{` - cleverly named "macros" - that makes writing macros super easy. `}</p>
    <p>{`Whichever editor you use, start finding the repetitive, tedious parts if your coding life and code that junk away with macros!   `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      